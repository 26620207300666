import { Project } from "../types";

export const BASE_PATH = "";

export const models = [
  {
    title: "Track",
    specs: [
      "Bottom bracket: BSA 68 mm",
      "Head tube: ZS 44 mm or ZS 32 mm",
      "Rear Axle: 120 mm",
      "Seat tube:  27.2 mm",
      "Max tyre width: 28 mm",
      "Brakes: None",
    ],
  },
  {
    title: "Road",
    specs: [
      "Bottom bracket: BSA 68 mm",
      "Head tube: ZS 44 mm or ZS 32 mm",
      "Rear axle: 12x124 mm or 10x130 mm",
      "Seat tube:  27.2 mm",
      "Max tyre width: 32 mm",
      "Brakes: V-brake or 140-160 mm flat mount",
    ],
  },
  {
    title: "All-Road",
    specs: [
      "Bottom bracket: BSA 73 mm",
      "Head tube: ZS 44 mm or ZS 32 mm",
      "Rear axle: 12x124 mm",
      "Seat tube:  27.2 mm",
      "Max tyre width: 45 mm",
      "Brakes: 160 mm flat mount",
    ],
  },
];

export const projects: Project[] = [
  {
    title: "Neoretro Road Racer",
    id: "neoretro-road-racer-project",
  },
  {
    title: "Pursuit Hommage",
    id: "pursuit-hommage-project",
  },
  {
    title: "Carbon Filament Shredder",
    id: "carbon-filament-shredder",
  },
  {
    title: "Dromotron",
    id: "dromotron",
  },
];
