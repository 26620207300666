import { A11y, Navigation } from "swiper/modules";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";

import { models } from "@/pages/constants";

import { SpecItem } from "../spec-item";

export const Specs = () => {
  const swiperParams: SwiperProps = {
    modules: [Navigation, A11y],
    navigation: true,
    spaceBetween: 20,
    rewind: true,
    breakpoints: {
      500: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <Swiper className="specifictaions" {...swiperParams}>
      {models.map(({ title, specs }) => (
        <SwiperSlide className="specifictaions__item" key={title} tag="figure">
          <SpecItem title={title} specs={specs} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
