import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { A11y, FreeMode, Keyboard, Mousewheel, Parallax, Scrollbar } from "swiper/modules";
import { Swiper, SwiperClass, SwiperProps, SwiperSlide } from "swiper/react";

import { Footer } from "@/components/footer";
import { Gallery } from "@/components/gallery";
import { usePreloadImages } from "@/components/gallery/hooks/use-preload-images";
import { Header } from "@/components/header";
import { Modal } from "@/components/modal";
import { useModal } from "@/components/modal/hooks";
import { Preloader } from "@/components/preloader";
import { Theme, ThemeConfig } from "@/types";

import { BASE_PATH } from "../constants";
import { usePageLoader } from "../hooks";
import { getSections, getSlideIndexBySection, importAll } from "../utils";

import "./styles.scss";

export const MainPage = () => {
  const { section, projectId } = useParams();
  const navigate = useNavigate();

  const { isPageLoading } = usePageLoader();
  const { isModalOpen, setIsModalOpen } = useModal();
  const backgroundsSrc = useMemo(
    () => importAll(require.context("@/assets/images/backgrounds", true, /\.(png|jpe?g|svg)$/)),
    [],
  );
  usePreloadImages(backgroundsSrc);

  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [theme, setTheme] = useState<ThemeConfig>({ header: Theme.Light, footer: Theme.Light });
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 500);

  const sections = useMemo(() => getSections(isMobile), [isMobile]);
  const navbarItems = useMemo(
    () =>
      sections.map(({ slideIndex, title, path, hidden }) => ({
        slideIndex,
        title,
        path,
        hidden,
      })),
    [sections],
  );

  const updateTheme = useCallback(() => {
    switch (section) {
      case "club":
        setTheme({ header: Theme.Light, footer: Theme.Dark });
        break;
      case "contact":
        setTheme({ header: Theme.Dark, footer: Theme.Dark });
        break;
      default:
        setTheme({ header: Theme.Light, footer: Theme.Light });
        break;
    }
  }, [section]);

  const handleSlideChange = () => {
    if (!swiper) return;
    const { activeIndex } = swiper;
    const activePath = `${BASE_PATH}${sections[activeIndex]?.path}`;
    navigate(activePath);
  };

  const closeGallery = () => {
    setIsModalOpen(false);
    navigate({ pathname: `${BASE_PATH}/${section}` });
  };

  const handleClickOutside = (e: React.MouseEvent) => {
    if (isModalOpen && e.target === e.currentTarget) {
      closeGallery();
    }
  };

  useEffect(() => {
    const slideIndex = getSlideIndexBySection(section, isMobile, sections);
    swiper?.slideTo(slideIndex);
  }, [isMobile, section, sections, swiper]);

  useEffect(() => {
    updateTheme();
  }, [updateTheme]);

  useEffect(() => {
    setIsModalOpen(!!projectId);
  }, [projectId, setIsModalOpen]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const swiperParams: SwiperProps = {
    className: "main",
    direction: "vertical",
    initialSlide: getSlideIndexBySection(section, isMobile, sections),
    keyboard: { enabled: true },
    modules: [Keyboard, Mousewheel, Parallax, Scrollbar, FreeMode, A11y],
    mousewheel: { enabled: true },
    scrollbar: { hide: false, draggable: false },
    slidesPerView: 1,
    speed: 700,
    watchSlidesProgress: true,
    longSwipes: false,
    preventInteractionOnTransition: true,
    resistance: true,
    resistanceRatio: 0,
    breakpoints: {
      425: {
        parallax: true,
      },
    },
    onSwiper: setSwiper,
    onSlideChange: handleSlideChange,
  };

  if (isPageLoading) {
    return <Preloader />;
  }

  return (
    <div
      className={`page-container${isModalOpen ? " modal-open" : ""}`}
      onMouseDown={handleClickOutside}
    >
      <Header swiper={swiper} navbarItems={navbarItems} theme={theme.header} />
      <Swiper {...swiperParams}>
        {sections.map(({ slideIndex, title, path, className, component: SectionContent }) => (
          <SwiperSlide
            tag="section"
            className={`section ${className}`}
            data-slide-index={slideIndex}
            data-title={title}
            data-path={path}
            key={title}
          >
            <SectionContent swiper={swiper} />
          </SwiperSlide>
        ))}
      </Swiper>
      {isModalOpen && (
        <Modal onClickOutside={handleClickOutside}>
          <Gallery closeGallery={closeGallery} />
        </Modal>
      )}
      <Footer theme={theme.footer} />
    </div>
  );
};
