import { Theme } from "@/types";

import "./styles.scss";

type ComponentProps = {
  theme: Theme;
};

export const Footer = ({ theme }: ComponentProps) => {
  return (
    <footer className="footer" data-theme={theme}>
      <div className="footer__text-container">
        <span className="footer__text">Oduwan - Built For Me</span>
        <span className="footer__text">Bespoke framesets hand-crafted in EU</span>
        <span className="footer__text">© 2025 All rights reserved</span>
      </div>
    </footer>
  );
};
