import { Customisation } from "@/sections/bikes/subsections/customisation/customisation";
import { Models } from "@/sections/bikes/subsections/models/models";
import { Club } from "@/sections/club";
import { Contact } from "@/sections/contact";
import { Hero } from "@/sections/hero";
import { Oduwan } from "@/sections/oduwan";
import { Spirit, Story } from "@/sections/oduwan/subsections";
import { Process } from "@/sections/process/process";
import { Section } from "@/types";

const getSlideIndex = (slideIndex: number, isMobile: boolean) => slideIndex + +isMobile;

export const getSections = (isMobile: boolean): Section[] => [
  {
    slideIndex: 0,
    className: "hero",
    title: "home",
    path: "/",
    component: Hero,
  },
  ...(isMobile
    ? [
        {
          slideIndex: 1,
          className: "oduwan story",
          title: "story",
          path: "/story",
          component: Story,
        },
        {
          slideIndex: 2,
          className: "oduwan spirit",
          title: "spirit",
          path: "/spirit",
          component: Spirit,
        },
      ]
    : [
        {
          slideIndex: 1,
          className: "oduwan",
          title: "oduwan",
          path: "/oduwan",
          component: Oduwan,
        },
      ]),
  {
    slideIndex: getSlideIndex(2, isMobile),
    className: "bikes-models",
    title: isMobile ? "models" : "bikes",
    path: "/models",
    component: Models,
  },
  {
    slideIndex: getSlideIndex(3, isMobile),
    className: "bikes-customisation",
    title: "customisation",
    path: "/customisation",
    component: Customisation,
    hidden: !isMobile,
  },
  {
    slideIndex: getSlideIndex(4, isMobile),
    className: "process",
    title: "process",
    path: "/process",
    component: Process,
  },
  {
    slideIndex: getSlideIndex(5, isMobile),
    className: "club",
    title: "club",
    path: "/club",
    component: Club,
  },
  {
    slideIndex: getSlideIndex(6, isMobile),
    className: "contact",
    title: "contact",
    path: "/contact",
    component: Contact,
  },
];

export const getSlideIndexBySection = (
  section: string | undefined,
  isMobile: boolean,
  sections: Section[],
) => {
  if (!section) return 0;
  if (
    (isMobile && section === "oduwan") ||
    (!isMobile && (section === "story" || section === "spirit"))
  ) {
    return 1;
  }
  return sections.findIndex(({ path }) => `/${section}` === path) ?? 0;
};

export const importAll = (r: __WebpackModuleApi.RequireContext): string[] =>
  r.keys().map((key) => r(key) as string);

export const preloadImages = (sources: string[]): HTMLImageElement[] => {
  return sources.map((src) => {
    const img = new Image();
    img.src = src;
    img.decode();
    return img;
  });
};
