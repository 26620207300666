import technology from "@/assets/images/sections/process/technology.jpg";
import { SectionHeader } from "@/sections/components/section-header";

import "./styles.scss";

export const Process = () => {
  return (
    <>
      <SectionHeader title="Technology" textAlign="left" />
      <div className="section__content-wrapper">
        <div className="section__content">
          <p className="section__text" data-swiper-parallax="-200">
            Oduwan goes beyond the limits of traditional framebuilding, mixing time-proven materials
            with state-of-the-art technology. <span className="br" /> The high-performance US-made
            Velospec steel. Aerospace-grade carbon tubing. 3D-printed titanium lugs.{" "}
            <span className="br" /> We strive to always be ahead, pushing the mastery forward, and
            not staying in the comfort zone. Never compromising on quality.
          </p>
          <img className="section__image" src={technology} alt="pipes" />
          <p className="section__text" data-swiper-parallax="-200">
            One must never doubt the safety of their bike. All Oduwan suppliers are carefully
            chosen, and materials are rigorously tested. <span className="br" />
            With dozens of Oduwans shredding streets of Europe, none have experienced any quality
            issues, and Oduwan doesn’t plan to change it. <span className="br" />
            Oduwan spirit implies to stand the test of time. This is why every frame comes with a
            lifetime warranty.
          </p>
        </div>
      </div>
      <SectionHeader title="Guarantee" textAlign="right" verticalAlign="bottom" />
    </>
  );
};
